import { FC } from 'react';
import moment from 'moment';
import { MessageBubble } from 'team-hero-ui';

import Avatar from 'components/Avatar';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { IMessage } from 'interfaces/Message.interface';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import MessageAttachmentWrapper from '../MessageAttachmentWrapper';
import { getMessageFileIdFromIriOrEmbed } from 'helpers/message/getMessageFileIdFromIriOrEmbed.helper';

interface IMessageProps {
  message: IMessage;
  isUnreadByMe: boolean;
}

const avatarSize = 'L';

const Message: FC<IMessageProps> = ({ message, isUnreadByMe }) => {
  const { currentUserContact } = useCurrentUserContact();

  const today = moment();

  const { dateTimeFormat, yesterdayFormat, lastWeekFormat } = useDateFormat();

  const displayDate = (messageDate: string) =>
    moment(messageDate).isSame(today, 'day')
      ? moment(messageDate).fromNow(true)
      : moment(messageDate).calendar({
          sameElse: dateTimeFormat,
          lastDay: yesterdayFormat,
          lastWeek: lastWeekFormat,
        });

  const attachments = message.attachments.map((attachment) => (
    <MessageAttachmentWrapper
      key={`message-attachment-${getMessageFileIdFromIriOrEmbed(attachment)}`}
      attachment={attachment}
      isSentMessage={message?.fromContact?.id === currentUserContact?.id}
    />
  ));

  return (
    <MessageBubble
      key={message.id}
      content={message.content}
      avatar={
        <Avatar
          sourceType='resource'
          size={avatarSize}
          images={message.fromContact?.avatar || ''}
          firstName={message.fromContact?.firstName || undefined}
          lastName={message.fromContact?.lastName || undefined}
          skipLoading
          thumbnailSize={'small'}
        />
      }
      attachments={attachments}
      createdAt={message.createdAt && displayDate(message.createdAt)}
      readAt={message.readAt && displayDate(message.readAt)}
      messageType={message.type}
      isSentMessage={message?.fromContact?.id === currentUserContact?.id}
      subject={
        message.type === 'email' && message.subject
          ? message.subject
          : undefined
      }
      isUnreadByMe={isUnreadByMe}
    />
  );
};

export default Message;
