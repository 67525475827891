import { createApi, retry } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './customBaseQuery/customBaseQuery';
import {
  deleteContactItem,
  getContactItem,
  getContacts,
  postContactItem,
  putContactItem,
} from './contacts/contacts.api';
import {
  postCredentials,
  postRefreshToken,
  getUserMe,
  postPassword,
  postUserItemLocale,
  getMagicLoginCredentials,
  putUserRequestPasswordMail,
  putUserItem,
  putUserEmailChangeConfirm,
} from './user/user.api';
import {
  getProjectMissions,
  getProjectMissionItem,
} from './projectMissions/projectMissions.api';
import {
  getProjectShiftItem,
  getProjectShifts,
  putProjectShiftItem,
  putProjectShiftItemStatus,
  putProjectShiftRemind,
  putProjectShiftsConfirmBriefing,
  getProjectShiftsWorkplanner,
} from './projectShifts/projectShifts.api';
import {
  deleteApplicationShiftItem,
  getShiftApplications,
  postApplicationShiftItem,
  putApplicationShiftItem,
  putOptimisticApplicationShiftItem,
} from './shiftApplications/shiftApplication.api';
import {
  deleteContactImageItem,
  getContactImageEntity,
  postContactImageEntityItem,
  postContactImageItem,
  putContactImageEntityItem,
} from './contactImages/contactImages.api';
import { getMissionFileEntity } from './projectMissionFiles/projectMissionFiles.api';
import {
  getContactAvailabilities,
  getContactAvailability,
  postContactAvailability,
  putContactAvailability,
  deleteContactAvailability,
} from './contactAvailability/contactAvailability.api';
import {
  getAbsenceCategories,
  getContactAbsence,
  getContactAbsences,
  postContactAbsence,
  putContactAbsence,
  deleteContactAbsence,
} from './contactAbsences/contactAbsences.api';
import {
  getContactDocuments,
  putContactDocument,
} from './contactDocuments/contactDocuments.api';
import {
  getContactDocumentFile,
  postContactDocumentFile,
  postContactDocumentFileEntity,
  deleteContactDocumentFile,
} from './contactDocumentFile/contactDocumentFile.api';
import {
  getContactInvoices,
  getContactInvoiceItem,
  postContactInvoice,
  putContactInvoice,
  postContactInvoiceFileItem,
} from './contactInvoices/contactInvoices.api';
import { getContactHolidayPlans } from './contactHolidayPlans/contactHolidayPlans.api';
import {
  deleteProjectTimesheets,
  getProjectTimesheetItem,
  getProjectTimesheets,
  postProjectTimesheets,
  putProjectTimesheets,
} from './projectTimesheets/projectTimesheets.api';
import {
  deleteProjectExpense,
  getProjectExpenseItem,
  getProjectExpenses,
  postProjectExpense,
  postProjectExpenseFile,
  putProjectExpense,
} from './projectExpenses/projectExpenses.api';
import { getCustomFields } from './customFields/customFields.api';
import {
  createGallery,
  getGalleries,
  getGalleryEntity,
} from './gallery/gallery.api';
import {
  deleteGalleryImage,
  getGalleryImageEntity,
  getGalleryImages,
  postGalleryImage,
} from './galleryImages/galleryImages.api';
import { postGalleryImageFile } from './galleryImagesFile/galleryImagesFile.api';
import { getRatings, postRatings, putRating } from './Rating/Rating.api';
import {
  getRatedCategory,
  getRatingCategories,
  postRatedCategories,
  putRatedCategory,
} from './RatingCategories/RatingCategories.api';
import {
  getStopwatches,
  getStopwatchItem,
  postStopwatchItem,
  putStopwatchItem,
} from './stopwatches/stopwatches.api';
import {
  getSignatureFileEntity,
  postSignatureFileEntityItem,
  postSignatureFileItem,
  putSignatureFileEntityItem,
} from './signatureFiles/signatureFiles.api';
import {
  getContactKfbDays,
  postContactKfbDaysItem,
  putContactKfbDaysItem,
} from './contactKfbDays/contactKfbDays.api';
import {
  deleteAbsenceFileItem,
  getAbsenceFileEntity,
  getAbsenceFilesEntity,
  postAbsenceFileEntityItem,
  postAbsenceFileItem,
  putAbsenceFileEntityItem,
} from './absenceFiles/absenceFiles.api';
import {
  deleteMessage,
  getMessage,
  getMessages,
  putMessage,
  postMessage,
} from './messages/messages.api';
import {
  deleteMessageFileEntityItem,
  getMessageFileEntity,
  getMessageFileItem,
  getMessageFilesEntity,
  postMessageFileEntityItem,
  postMessageFileItem,
  putMessageFileEntityItem,
} from './messageFiles/messageFiles.api';
import {
  deleteMessageConversation,
  getMessageConversation,
  getMessageConversations,
  postMessageConversation,
  putMessageConversation,
  putMessageConversationReadUnread,
} from './messageConversations/messageConversations.api';
import {
  deleteProjectItem,
  getProjectItem,
  getProjects,
  postProjectItem,
  putProjectItem,
} from './projects/projects.api';
import { getRewardProfile } from './rewardProfile/rewardProfile.api';
import { getSystemSettings } from './systemSettings/systemSettings.api';
import {
  deleteApplicationJobAdSourceItem,
  getApplicationJobAdSources,
  postApplicationJobAdSourceItem,
} from './applicationJobAdSources/applicationJobAdSources.api';
import {
  deleteJobAdItem,
  getJobAdItem,
  postJobAdItem,
  putJobAdItem,
  putJobAdItemStatus,
} from './jobAds/jobAds.api';
import {
  getApplicationItem,
  getApplications,
  postApplicationItem,
  putApplicationItem,
} from './applications/applications.api';
import {
  deleteApplicationImageItem,
  getApplicationImageEntity,
  postApplicationImageEntityItem,
  postApplicationImageItem,
  putApplicationImageEntityItem,
} from './applicationImages/applicationImages.api';
import {
  getApplicationSourceItem,
  getApplicationSources,
} from './applicationSources/applicationSources.api';
import {
  getApplicationInterviewInviteItem,
  getApplicationInterviewInvites,
  postApplicationInterviewInviteItem,
  putApplicationInterviewInviteItem,
} from './applicationInterviewInvites/applicationInterviewInvites.api';
import {
  deleteApplicationDocumentItem,
  getApplicationDocumentItem,
  getApplicationDocuments,
  postApplicationDocumentItem,
  putApplicationDocumentItem,
} from './applicationDocuments/applicationDocuments.api';
import {
  deleteApplicationDocumentFileItem,
  getApplicationDocumentFileEntityItem,
  postApplicationDocumentFileEntityItem,
  postApplicationDocumentFileItem,
} from './applicationDocumentsFiles/applicationDocumentFiles.api';
import {
  deleteInventoryItem,
  getInventories,
  getInventoryItem,
  postInventoryItem,
  putInventoryItem,
} from './inventories/inventories.api';
import {
  deleteInventoryImageItem,
  getInventoryImageEntity,
  postInventoryImageEntityItem,
  postInventoryImageItem,
  putInventoryImageEntityItem,
} from './inventoryImages/inventoryImages.api';
import {
  deleteShiftInventoryItem,
  getShiftInventories,
  getShiftInventoryItem,
  postShiftInventoryItem,
  putShiftInventoryItem,
} from './shiftInventory/shiftInventory.api';
import {
  getUserNotificationItem,
  getUserNotifications,
  postUserNotificationsMarkAllAsRead,
  postUserNotificationsUnsetAllNew,
  putUserNotificationItem,
} from './userNotifications/userNotifications.api';
import {
  getDocumentTemplateItem,
  getDocumentTemplates,
} from './documentTemplates/documentTemplates.api';
import {
  getContactPayslipFileItem,
  getContactPayslipFiles,
} from './contactPayslipFile/contactPayslipFile.api';
import {
  getCampaignItem,
  getCampaigns,
  postCampaignClick,
} from './campaigns/campaigns.api';
import { getWidgetItem, getWidgets } from './widgets/widgets.api';
import { getWTAEntries, getWTAEntryItem } from './wtaEntries/wtaEntries.api';
import { getCompanies, getCompanyItem } from './companies/companies.api';
import {
  getMessageConversationParticipantItem,
  getMessageConversationParticipants,
} from 'services/messageConversationParticipants/messageConversationParticipants.api';
import {
  deleteUserNotificationToken,
  getUserNotificationTokenItem,
  getUserNotificationTokens,
  postUserNotificationToken,
} from 'services/userNotificationTokens/userNotificationTokens.api';

export const teamHeroApi = createApi({
  baseQuery: retry(baseQueryWithReauth, { maxRetries: 1 }),
  tagTypes: [
    'AbsenceCategory',
    'AbsenceFile',
    'Application',
    'ApplicationDocument',
    'ApplicationDocumentFile',
    'ApplicationImage',
    'ApplicationInterviewInvite',
    'ApplicationJobAdSources',
    'ApplicationSource',
    'Campaign',
    'Company',
    'Contact',
    'ContactAbsence',
    'ContactAvailability',
    'ContactDocument',
    'ContactDocumentFile',
    'ContactHolidayPlan',
    'ContactImage',
    'ContactInvoice',
    'ContactInvoiceFile',
    'ContactKfbDays',
    'ContactPayslipFile',
    'DocumentTemplate',
    'Gallery',
    'GalleryImage',
    'Inventory',
    'InventoryImage',
    'JobAd',
    'Message',
    'MessageConversation',
    'MessageConversationParticipant',
    'MessageFile',
    'Project',
    'ProjectExpense',
    'ProjectMission',
    'ProjectShift',
    'ProjectTimesheet',
    'Rating',
    'RatingCategory',
    'RewardProfile',
    'SettingsCustomField',
    'ShiftApplication',
    'ShiftInventory',
    'SignatureFile',
    'Stopwatch',
    'SystemSetting',
    'UserMe',
    'UserNotification',
    'UserNotificationToken',
    'WTAEntry',
    'Widget',
  ],
  endpoints: (build) => ({
    postCredentials: postCredentials(build),
    postRefreshToken: postRefreshToken(build),
    getUserMe: getUserMe(build),
    postUserItemLocale: postUserItemLocale(build),
    putUserRequestPasswordMail: putUserRequestPasswordMail(build),
    putUserItem: putUserItem(build),
    putUserEmailChangeConfirm: putUserEmailChangeConfirm(build),
    getMagicLoginCredentials: getMagicLoginCredentials(build),
    getMissionFileEntity: getMissionFileEntity(build),
    putProjectShiftRemind: putProjectShiftRemind(build),
    getProjectMissions: getProjectMissions(build),
    getProjectMissionItem: getProjectMissionItem(build),
    getProjectExpenses: getProjectExpenses(build),
    getProjectExpenseItem: getProjectExpenseItem(build),
    postProjectExpense: postProjectExpense(build),
    putProjectExpense: putProjectExpense(build),
    deleteProjectExpense: deleteProjectExpense(build),
    postProjectExpenseFile: postProjectExpenseFile(build),
    getProjectShifts: getProjectShifts(build),
    getProjectShiftItem: getProjectShiftItem(build),
    putProjectShiftItemStatus: putProjectShiftItemStatus(build),
    getShiftApplications: getShiftApplications(build),
    postApplicationShiftItem: postApplicationShiftItem(build),
    putApplicationShiftItem: putApplicationShiftItem(build),
    putOptimisticApplicationShiftItem: putOptimisticApplicationShiftItem(build),
    deleteApplicationShiftItem: deleteApplicationShiftItem(build),
    getContacts: getContacts(build),
    getContactItem: getContactItem(build),
    postContactItem: postContactItem(build),
    getContactInvoices: getContactInvoices(build),
    getContactInvoiceItem: getContactInvoiceItem(build),
    postContactInvoice: postContactInvoice(build),
    putContactInvoice: putContactInvoice(build),
    postContactInvoiceFileItem: postContactInvoiceFileItem(build),
    putContactItem: putContactItem(build),
    deleteContactItem: deleteContactItem(build),
    getContactDocuments: getContactDocuments(build),
    putContactDocument: putContactDocument(build),
    getContactDocumentFile: getContactDocumentFile(build),
    postContactDocumentFile: postContactDocumentFile(build),
    deleteContactDocumentFile: deleteContactDocumentFile(build),
    postContactDocumentFileEntity: postContactDocumentFileEntity(build),
    getContactImageEntity: getContactImageEntity(build),
    postContactImageEntityItem: postContactImageEntityItem(build),
    putContactImageEntityItem: putContactImageEntityItem(build),
    deleteContactImageItem: deleteContactImageItem(build),
    postContactImageItem: postContactImageItem(build),
    putProjectShiftItem: putProjectShiftItem(build),
    putProjectShiftsConfirmBriefing: putProjectShiftsConfirmBriefing(build),
    getGalleries: getGalleries(build),
    getGalleryEntity: getGalleryEntity(build),
    createGallery: createGallery(build),
    getGalleryImages: getGalleryImages(build),
    getGalleryImageEntity: getGalleryImageEntity(build),
    postGalleryImage: postGalleryImage(build),
    deleteGalleryImage: deleteGalleryImage(build),
    postGalleryImageFile: postGalleryImageFile(build),
    getContactHolidayPlans: getContactHolidayPlans(build),
    getProjectTimesheets: getProjectTimesheets(build),
    getProjectTimesheetItem: getProjectTimesheetItem(build),
    postProjectTimesheets: postProjectTimesheets(build),
    deleteProjectTimesheets: deleteProjectTimesheets(build),
    putProjectTimesheets: putProjectTimesheets(build),
    getCustomFields: getCustomFields(build),
    getRatings: getRatings(build),
    postRating: postRatings(build),
    putRating: putRating(build),
    getRatingCategories: getRatingCategories(build),
    getRatedCategory: getRatedCategory(build),
    postRatedCategories: postRatedCategories(build),
    putRatedCategory: putRatedCategory(build),
    getStopwatches: getStopwatches(build),
    getStopwatchItem: getStopwatchItem(build),
    postStopwatchItem: postStopwatchItem(build),
    putStopwatchItem: putStopwatchItem(build),
    getSignatureFileEntity: getSignatureFileEntity(build),
    postSignatureFileEntityItem: postSignatureFileEntityItem(build),
    putSignatureFileEntityItem: putSignatureFileEntityItem(build),
    postSignatureFileItem: postSignatureFileItem(build),
    postPassword: postPassword(build),
    getContactKfbDays: getContactKfbDays(build),
    putContactKfbDaysItem: putContactKfbDaysItem(build),
    postContactKfbDaysItem: postContactKfbDaysItem(build),
    getAbsenceCategories: getAbsenceCategories(build),
    getContactAbsences: getContactAbsences(build),
    postContactAbsence: postContactAbsence(build),
    putContactAbsence: putContactAbsence(build),
    deleteContactAbsence: deleteContactAbsence(build),
    getContactAbsence: getContactAbsence(build),
    getAbsenceFilesEntity: getAbsenceFilesEntity(build),
    getAbsenceFileEntity: getAbsenceFileEntity(build),
    postAbsenceFileEntityItem: postAbsenceFileEntityItem(build),
    putAbsenceFileEntityItem: putAbsenceFileEntityItem(build),
    deleteAbsenceFileItem: deleteAbsenceFileItem(build),
    postAbsenceFileItem: postAbsenceFileItem(build),
    getContactAvailabilities: getContactAvailabilities(build),
    getContactAvailability: getContactAvailability(build),
    postContactAvailability: postContactAvailability(build),
    putContactAvailability: putContactAvailability(build),
    deleteContactAvailability: deleteContactAvailability(build),
    getMessages: getMessages(build),
    getMessage: getMessage(build),
    postMessage: postMessage(build),
    putMessage: putMessage(build),
    deleteMessage: deleteMessage(build),
    getMessageFilesEntity: getMessageFilesEntity(build),
    getMessageFileEntity: getMessageFileEntity(build),
    postMessageFileEntityItem: postMessageFileEntityItem(build),
    putMessageFileEntityItem: putMessageFileEntityItem(build),
    deleteMessageFileEntityItem: deleteMessageFileEntityItem(build),
    getMessageFileItem: getMessageFileItem(build),
    postMessageFileItem: postMessageFileItem(build),
    getMessageConversations: getMessageConversations(build),
    getMessageConversation: getMessageConversation(build),
    postMessageConversation: postMessageConversation(build),
    putMessageConversation: putMessageConversation(build),
    getMessageConversationParticipants:
      getMessageConversationParticipants(build),
    getMessageConversationParticipantItem:
      getMessageConversationParticipantItem(build),
    putMessageConversationReadUnread: putMessageConversationReadUnread(build),
    deleteMessageConversation: deleteMessageConversation(build),
    getProjects: getProjects(build),
    getProjectItem: getProjectItem(build),
    postProjectItem: postProjectItem(build),
    putProjectItem: putProjectItem(build),
    deleteProjectItem: deleteProjectItem(build),
    getRewardProfile: getRewardProfile(build),
    getSystemSettings: getSystemSettings(build),
    getJobAdItem: getJobAdItem(build),
    postJobAdItem: postJobAdItem(build),
    putJobAdItem: putJobAdItem(build),
    putJobAdItemStatus: putJobAdItemStatus(build),
    deleteJobAdItem: deleteJobAdItem(build),
    getApplicationJobAdSources: getApplicationJobAdSources(build),
    postApplicationJobAdSourceItem: postApplicationJobAdSourceItem(build),
    deleteApplicationJobAdSourceItem: deleteApplicationJobAdSourceItem(build),
    getApplications: getApplications(build),
    getApplicationItem: getApplicationItem(build),
    postApplicationItem: postApplicationItem(build),
    putApplicationItem: putApplicationItem(build),
    getApplicationImageEntity: getApplicationImageEntity(build),
    postApplicationImageEntityItem: postApplicationImageEntityItem(build),
    putApplicationImageEntityItem: putApplicationImageEntityItem(build),
    deleteApplicationImageItem: deleteApplicationImageItem(build),
    postApplicationImageItem: postApplicationImageItem(build),
    getApplicationSources: getApplicationSources(build),
    getApplicationSourceItem: getApplicationSourceItem(build),
    getApplicationInterviewInviteItem: getApplicationInterviewInviteItem(build),
    getApplicationInterviewInvites: getApplicationInterviewInvites(build),
    postApplicationInterviewInviteItem:
      postApplicationInterviewInviteItem(build),
    putApplicationInterviewInviteItem: putApplicationInterviewInviteItem(build),
    getApplicationDocuments: getApplicationDocuments(build),
    getApplicationDocumentItem: getApplicationDocumentItem(build),
    postApplicationDocumentItem: postApplicationDocumentItem(build),
    putApplicationDocumentItem: putApplicationDocumentItem(build),
    getApplicationDocumentFileEntityItem:
      getApplicationDocumentFileEntityItem(build),
    deleteApplicationDocumentItem: deleteApplicationDocumentItem(build),
    postApplicationDocumentFileEntityItem:
      postApplicationDocumentFileEntityItem(build),
    postApplicationDocumentFileItem: postApplicationDocumentFileItem(build),
    deleteApplicationDocumentFileItem: deleteApplicationDocumentFileItem(build),
    getInventories: getInventories(build),
    getInventoryItem: getInventoryItem(build),
    postInventoryItem: postInventoryItem(build),
    putInventoryItem: putInventoryItem(build),
    deleteInventoryItem: deleteInventoryItem(build),
    deleteInventoryImageItem: deleteInventoryImageItem(build),
    getInventoryImageEntity: getInventoryImageEntity(build),
    postInventoryImageEntityItem: postInventoryImageEntityItem(build),
    postInventoryImageItem: postInventoryImageItem(build),
    putInventoryImageEntityItem: putInventoryImageEntityItem(build),
    getShiftInventories: getShiftInventories(build),
    getShiftInventoryItem: getShiftInventoryItem(build),
    postShiftInventoryItem: postShiftInventoryItem(build),
    putShiftInventoryItem: putShiftInventoryItem(build),
    deleteShiftInventoryItem: deleteShiftInventoryItem(build),
    getUserNotifications: getUserNotifications(build),
    getUserNotificationItem: getUserNotificationItem(build),
    postUserNotificationMarkAllAsRead:
      postUserNotificationsMarkAllAsRead(build),
    postUserNotificationUnsetAllNew: postUserNotificationsUnsetAllNew(build),
    putUserNotificationItem: putUserNotificationItem(build),
    getDocumentTemplates: getDocumentTemplates(build),
    getDocumentTemplateItem: getDocumentTemplateItem(build),
    getContactPayslipFileItem: getContactPayslipFileItem(build),
    getContactPayslipFiles: getContactPayslipFiles(build),
    getCampaigns: getCampaigns(build),
    getCampaignItem: getCampaignItem(build),
    postCampaignClick: postCampaignClick(build),
    getWidgets: getWidgets(build),
    getWidgetItem: getWidgetItem(build),
    getWTAEntries: getWTAEntries(build),
    getWTAEntryItem: getWTAEntryItem(build),
    getCompanies: getCompanies(build),
    getCompanyItem: getCompanyItem(build),
    getProjectShiftsWorkplanner: getProjectShiftsWorkplanner(build),
    getUserNotificationTokens: getUserNotificationTokens(build),
    getUserNotificationTokenItem: getUserNotificationTokenItem(build),
    postUserNotificationToken: postUserNotificationToken(build),
    deleteUserNotificationToken: deleteUserNotificationToken(build),
  }),
});

export const {
  usePostCredentialsMutation,
  usePostRefreshTokenMutation,
  useGetUserMeQuery,
  usePostUserItemLocaleMutation,
  usePutUserRequestPasswordMailMutation,
  usePutUserItemMutation,
  usePutUserEmailChangeConfirmMutation,
  usePutProjectShiftRemindMutation,
  useGetProjectMissionsQuery,
  useGetProjectMissionItemQuery,
  useGetMissionFileEntityQuery,
  useLazyGetMissionFileEntityQuery,
  useGetProjectShiftsQuery,
  useGetProjectShiftItemQuery,
  usePutProjectShiftItemMutation,
  usePutProjectShiftItemStatusMutation,
  useGetProjectExpensesQuery,
  useGetProjectExpenseItemQuery,
  usePostProjectExpenseMutation,
  usePutProjectExpenseMutation,
  useDeleteProjectExpenseMutation,
  usePostProjectExpenseFileMutation,
  useGetShiftApplicationsQuery,
  usePostApplicationShiftItemMutation,
  usePutApplicationShiftItemMutation,
  usePutOptimisticApplicationShiftItemMutation,
  useDeleteApplicationShiftItemMutation,
  useGetContactsQuery,
  useGetContactItemQuery,
  usePostContactItemMutation,
  usePutContactItemMutation,
  useDeleteContactItemMutation,
  useGetContactImageEntityQuery,
  useGetContactDocumentsQuery,
  usePutContactDocumentMutation,
  useGetContactDocumentFileQuery,
  usePostContactDocumentFileMutation,
  useDeleteContactDocumentFileMutation,
  usePostContactDocumentFileEntityMutation,
  usePutProjectShiftsConfirmBriefingMutation,
  useGetGalleriesQuery,
  useGetGalleryEntityQuery,
  useCreateGalleryMutation,
  useGetGalleryImagesQuery,
  useGetGalleryImageEntityQuery,
  usePostGalleryImageMutation,
  useDeleteGalleryImageMutation,
  usePostGalleryImageFileMutation,
  useGetContactAvailabilityQuery,
  useGetContactAvailabilitiesQuery,
  useDeleteContactAvailabilityMutation,
  useGetContactHolidayPlansQuery,
  useLazyGetContactHolidayPlansQuery,
  useGetContactInvoicesQuery,
  useGetContactInvoiceItemQuery,
  usePostContactInvoiceMutation,
  usePostContactInvoiceFileItemMutation,
  usePutContactInvoiceMutation,
  useGetProjectTimesheetsQuery,
  useGetProjectTimesheetItemQuery,
  usePostProjectTimesheetsMutation,
  useDeleteProjectTimesheetsMutation,
  usePutProjectTimesheetsMutation,
  useGetContactAbsencesQuery,
  useLazyGetContactAbsencesQuery,
  useDeleteContactAbsenceMutation,
  useGetCustomFieldsQuery,
  useGetRatingCategoriesQuery,
  useGetRatedCategoryQuery,
  usePostRatedCategoriesMutation,
  useGetRatingsQuery,
  usePostRatingMutation,
  usePutRatingMutation,
  usePutRatedCategoryMutation,
  useGetStopwatchesQuery,
  useGetStopwatchItemQuery,
  usePostStopwatchItemMutation,
  usePutStopwatchItemMutation,
  useGetSignatureFileEntityQuery,
  usePostSignatureFileEntityItemMutation,
  usePutSignatureFileEntityItemMutation,
  usePostSignatureFileItemMutation,
  usePostPasswordMutation,
  useGetAbsenceCategoriesQuery,
  useGetContactAbsenceQuery,
  useGetAbsenceFilesEntityQuery,
  useGetAbsenceFileEntityQuery,
  usePutAbsenceFileEntityItemMutation,
  usePostAbsenceFileEntityItemMutation,
  usePostAbsenceFileItemMutation,
  useDeleteAbsenceFileItemMutation,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useGetMessageQuery,
  usePostMessageMutation,
  usePutMessageMutation,
  useDeleteMessageMutation,
  useGetMessageFileEntityQuery,
  useGetMessageFilesEntityQuery,
  usePostMessageFileEntityItemMutation,
  usePostMessageFileItemMutation,
  usePutMessageFileEntityItemMutation,
  useDeleteMessageFileEntityItemMutation,
  useGetMessageConversationQuery,
  useGetMessageConversationsQuery,
  usePostMessageConversationMutation,
  usePutMessageConversationMutation,
  usePutMessageConversationReadUnreadMutation,
  useDeleteMessageConversationMutation,
  useGetProjectsQuery,
  useGetProjectItemQuery,
  usePostProjectItemMutation,
  usePutProjectItemMutation,
  useDeleteProjectItemMutation,
  useGetRewardProfileQuery,
  useGetSystemSettingsQuery,
  useGetApplicationsQuery,
  useGetApplicationItemQuery,
  usePostApplicationImageEntityItemMutation,
  usePostApplicationImageItemMutation,
  usePostApplicationItemMutation,
  usePutApplicationImageEntityItemMutation,
  usePutApplicationItemMutation,
  useGetJobAdItemQuery,
  useGetApplicationJobAdSourcesQuery,
  useGetApplicationSourcesQuery,
  useGetApplicationSourceItemQuery,
  useGetApplicationInterviewInviteItemQuery,
  useGetApplicationInterviewInvitesQuery,
  usePutApplicationInterviewInviteItemMutation,
  useGetApplicationDocumentsQuery,
  useGetApplicationDocumentFileEntityItemQuery,
  usePostApplicationDocumentFileEntityItemMutation,
  usePostApplicationDocumentFileItemMutation,
  useDeleteApplicationDocumentFileItemMutation,
  useDeleteApplicationDocumentItemMutation,
  useGetApplicationDocumentItemQuery,
  usePostApplicationDocumentItemMutation,
  usePutApplicationDocumentItemMutation,
  useGetShiftInventoriesQuery,
  useGetShiftInventoryItemQuery,
  usePostShiftInventoryItemMutation,
  usePutShiftInventoryItemMutation,
  useDeleteShiftInventoryItemMutation,
  useGetInventoryItemQuery,
  useGetInventoriesQuery,
  useGetUserNotificationsQuery,
  useLazyGetUserNotificationsQuery,
  useGetUserNotificationItemQuery,
  usePutUserNotificationItemMutation,
  usePostUserNotificationMarkAllAsReadMutation,
  usePostUserNotificationUnsetAllNewMutation,
  useGetDocumentTemplatesQuery,
  useGetDocumentTemplateItemQuery,
  useGetContactPayslipFilesQuery,
  useGetContactKfbDaysQuery,
  useGetCampaignItemQuery,
  useGetCampaignsQuery,
  usePostCampaignClickMutation,
  useGetWidgetsQuery,
  useGetWidgetItemQuery,
  useGetWTAEntriesQuery,
  useGetCompaniesQuery,
  useGetCompanyItemQuery,
  useGetProjectShiftsWorkplannerQuery,
  useLazyGetProjectShiftsQuery,
  useLazyGetCompanyItemQuery,
  useLazyGetDocumentTemplateItemQuery,
  useLazyGetApplicationItemQuery,
  useLazyGetProjectShiftsWorkplannerQuery,
  useLazyGetContactItemQuery,
  useLazyGetProjectItemQuery,
  useLazyGetProjectMissionItemQuery,
  useLazyGetProjectMissionsQuery,
  useLazyGetMessageConversationsQuery,
  useGetMessageConversationParticipantsQuery,
  useGetMessageConversationParticipantItemQuery,
  usePostUserNotificationTokenMutation,
  useGetUserNotificationTokensQuery,
} = teamHeroApi;
